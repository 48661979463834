import React, { Fragment } from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from './../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Content from '../../components/articles/Content'
import NewsArticleLink from '../../components/articles/Listings/NewsArticleLink'
import Newsletter from '../../components/Newsletter'

export default function ArticleTemplate({ data }) {
  const { article, site, relatedArticles } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${article.categoryLink}/${article.slug}`
  )

  const breadcrumbs = [
    {
      name: 'News',
      uri: '/news',
    },
    {
      name: article.category,
      uri: article.categoryLink,
    },
    {
      name: article.title,
      uri: `${article.categoryLink}/${article.slug}`,
    },
  ]

  return (
    <Page className="p-article p-newsArticle has-fingerPrints" datoIndex>
      <HelmetDatoCms seo={article.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {article.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: article.canonicalUrl,
              href: article.canonicalUrl,
            },
          ]}
        />
      )}

      <article id={article.id} className="c-article">
        <div className="container py-5">
          <header>
            <HeaderInfo
              date={article.pubDateTime}
              category={article.category}
              categoryLink={article.categoryLink}
            />
            <div className="row">
              <div className="col-12 col-lg-9 offset-lg-3">
                <h1 className="c-articleTitle">{article.title}</h1>
              </div>
            </div>
          </header>

          {/* <!-- Hero --> */}
          <section>
            <div className="row">
              <div className="col-12 col-lg-3 mb-2">
                <ShareButtons shareUrl={shareUrl} />
              </div>
              <div className="col-12 col-lg-9">
                {article.heroImage && (
                  <div className="c-articleHeroImageWrapper">
                    <Img
                      fluid={article.heroImage.fluid}
                      title={article.heroImage.title}
                      alt={article.heroImage.alt}
                      loading="eager"
                      className="c-articleHeroImage img-fluid"
                    />
                  </div>
                )}
                {/* <!-- Intro --> */}
                {article.leadIn && (
                  <Fragment>
                    <h2 className="c-articleLeadIn">{article.leadIn}</h2>
                    <hr />
                  </Fragment>
                )}

                {/* <!-- Body --> */}
                <Content content={article.content} />
              </div>
            </div>
          </section>
        </div>
      </article>

      {/* <!-- Related --> */}
      <section
        id="latest-editorial"
        className="c-latestEditorial"
        data-datocms-noindex
      >
        <div className="container">
          <div className="c-latestEditorial__inner">
            <div className="row">
              <div className="c-latestEditorial__header col-12">
                <h3 className="c-latestEditorial__header__title">
                  Related News
                </h3>
                <Link to="/news" className="c-postReturnLink">
                  <img src="/icons/arrow-right.svg" className="c-icon mr-2" />
                  <span className="c-postReturnLink__text">All News</span>
                </Link>
              </div>
            </div>

            <div className="row">
              {relatedArticles.edges.map(({ node }) => (
                <div key={node.slug} className="col-6 col-lg-4">
                  <NewsArticleLink article={node} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query NewsArticleQuery($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    article: datoCmsNewsArticle(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      leadIn
      heroImage {
        fluid(imgixParams: { w: "850", fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      content {
        ...ArticleContentFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    relatedArticles: allDatoCmsNewsArticle(
      filter: { slug: { ne: $slug } }
      sort: { fields: displayDate, order: DESC }
      limit: 3
    ) {
      edges {
        node {
          title
          slug
          relPath
          category
          categoryLink
          pubDateTime
          heroImage {
            url
            alt
            title
            customData
          }
          thumbnailImage {
            url
            alt
            title
            customData
          }
        }
      }
    }
  }
`
